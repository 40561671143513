var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"543f75b4473cd429bc50115186bce791363a96cc"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN } from '@/constants/constants';

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
  ],
});
